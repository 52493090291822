$(function(){
    initAddressAutocomplete();
});

/**
 * Sets up the listeners
 * @param {Array} options The available options
 *  {String} classForm Name of the wrapper form's class to initialize
 *  {String} classFormInited Class to be added once the form is initialized
 *  {String} classSelect The target select's class
 *  {String} placeholder The placeholder text for the select
 *  {Int} minimumInputLength The minimum number of characters to launch the autocompletion
 */
function initAddressAutocomplete (options) {
    // sets up the options if nothing is passed
    options = (typeof options === "undefined") ? {} : options;
    // default values
    if (!("classForm" in options)) {options.classForm = "form-with-address2autocomplete";}
    if (!("classFormInited" in options)) {options.classFormInited = "inited";}
    if (!("classSelect" in options)) {options.classSelect = "address2autocomplete";}
    if (!("placeholder" in options)) {options.placeholder = "Renseignez votre adresse";}
    if (!("minimumInputLength" in options)) {options.minimumInputLength = 5;}
    
    $('.'+options.classForm+':not(.'+options.classFormInited+')').each(function(){
        var form = $(this);
        
        // the field that listens to the autocompletion
        var address2autocomplete = form.find('.address2autocomplete');
        
        address2autocomplete
            .select2({
                ajax: {
                    url: "https://api-adresse.data.gouv.fr/search",
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        return {
                            q: params.term,
                        };
                    },
                    processResults: function (data, params) {
                        var locations = addressAutocompleteHandleAddressApiResults(data.features);
        
                        return {
                            results: locations,
                        };
                    },
                    cache: true
                },
        
                placeholder: options.placeholder,
                language: "fr",
                escapeMarkup: function (markup) { return markup; },
                minimumInputLength: options.minimumInputLength,
                templateResult: addressAutocompleteFormatRepo,
                templateSelection: addressAutocompleteFormatRepoSelection
            })
            .on("select2:select", function (e) { // a suggestion is selected, populates the individual fields
                form.find('input[name=address]').val(e.params.data.address);
                form.find('input[name=zip]').val(e.params.data.zip);
                form.find('input[name=city]').val(e.params.data.city);
                form.find('input[name=latitude], input[name=lat]').val(e.params.data.latitude);
                form.find('input[name=longitude], input[name=lng]').val(e.params.data.longitude);
            })
            .data('select2').listeners['*'].push(function(name, target) {
                // opens the search bar when the input is empty
                // IE11 issue: when the dropdown is open, it is impossible to lose focus from the select
                if(name == 'focus' && address2autocomplete.val() == null) {
                    $(this.$element).select2("open");
                }
            });
        
        // adds the class to avoid double initialization
        form.addClass(options.classFormInited);
    });
}

// ######## Helper functions

function addressAutocompleteFormatRepo (repo) {
    if (repo.loading) {
        return "Recherche en cours";
    }

    return "<div class='select2-result-repository clearfix'>" + repo.full_address + "</div>";
}

function addressAutocompleteFormatRepoSelection (repo) {
    return repo.full_address || "Renseignez votre adresse";
}

function addressAutocompleteHandleAddressApiResults(results) {
    var out = [];

    $.each(results, function (index, result) {
        out.push({
            'id': result.properties.id,
            "type": result.properties.type,
            'latitude': result.geometry.coordinates[1],
            'longitude': result.geometry.coordinates[0],
            'address': result.properties.name,
            'zip': result.properties.postcode,
            "citycode": result.properties.citycode,
            'city': result.properties.city,
            "context": result.properties.context,
            'full_address': result.properties.label,
        });
    });

    return out;
}
