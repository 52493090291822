$(function(){
    $(document).foundation();
});

function contactSent (data) {
    $('.ajaxform.form-'+data.uidForm).replaceWith(data.html);
}

// Ajout du svg arrow pour chosen page inscription
$(document).ready(function(){
    $('.wrapper-select.with-arrow-chosen .chosen-container').append('<svg class="arrow"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#chevron-down"></use></svg>');
});
