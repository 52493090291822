$(document).ready(function () {

    var linkHeaderModules = $('.header-backoffice a.modules');
    var linkHeaderAccount = $('.header-backoffice a.account');

    var moduleDropdown = $('.header-backoffice #modules-dropdown');
    var accountDropdown = $('.header-backoffice #account-dropdown');

    $(document).click(function(){
        moduleDropdown.hide();
        accountDropdown.hide();
    });

    linkHeaderAccount.click(function (e) {
        moduleDropdown.hide();
        e.stopPropagation();
        accountDropdown.toggle();
    });

    linkHeaderModules.click(function (e) {
        accountDropdown.hide();
        e.stopPropagation();
        moduleDropdown.toggle();
    });

})