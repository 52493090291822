// https://stripe.com/docs/custom-form

function stripeCreateToken() {
    var form = $('#payment-form-card');
    
    // This identifies your website in the createToken call below
    Stripe.setPublishableKey($('.stripe-form').attr('data-stripe_public_key'));

    // vérifie les données fournies
    if (!form.validate()) {
        alert("Veuillez remplir les champs en rouge");
        return;
    }

    form.addClass('working');

    Stripe.card.createToken(form, stripeResponseHandler);
}

//Stripe.card.createToken($form, stripeResponseHandler);

function stripeResponseHandler(status, response) {
    var form = $('#payment-form-card');
    var errorCallout = form.find('.payment-errors');

    if (response.error) {
        // Show the errors on the form
        errorCallout.show().find('.text').text(stripeGetErrorMessage(response.error.code));
        form.removeClass('working');
    } else {
        errorCallout.hide();
        // response contains id and card, which contains additional card details
        var token = response.id;
        // Insert the token into the form so it gets submitted to the server
        form.append($('<input type="hidden" name="stripeToken" />').val(token));
        // and submit
        form.removeClass('working');
        form.find('.sendbutton')
            .removeClass('submitSpecial')
            .removeAttr('onclick')
            .click();
    }
}

function stripeGetErrorMessage (code) {
    var errorMessages = {
        incorrect_number: "Le numéro de carte saisi est incorrect",
        invalid_number: "Le numéro de carte saisi est invalide",
        invalid_expiry_month: "Le mois d'expiration est invalide",
        invalid_expiry_year: "L'année d'expiration est invalide",
        invalid_cvc: "Le code de sécurité saisi est invalide",
        expired_card: "La carte est expirée",
        incorrect_cvc: "Le code de sécurité saisi est incorrect",
        //incorrect_zip: "The card's zip code failed validation.",
        card_declined: "La carte n'a pas été acceptée",
        //missing: "There is no card on a customer that is being charged.",
        processing_error: "Le serveur de vérification a rencontré une erreur, veuillez retenter le paiement ultérieurement",
        //rate_limit:  "An error occurred due to requests hitting the API too quickly. Please let us know if you're consistently running into this error."
    };
    if (code in errorMessages) {
        return errorMessages[code];
    } else {
        return "Une erreur inconnue s'est produite, veuillez retenter le paiement ultérieurement";
    }
}