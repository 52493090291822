var LSTimer; // timer pour les livesearches de la page
$(document).ready(function(){
    standardInit();

	// formulaires IBAN
	ibanFormInit();

	//$('body').append("<a href='#top' id='toTop'></a>");
	//$("#toTop").scrollToTop(500);

	decodeEmails();
});

// plugin scroll up
(function(a){a.fn.scrollToTop=function(c){var d={speed:800};c&&a.extend(d,{speed:c});return this.each(function(){var b=a(this);a(window).scroll(function(){100<a(this).scrollTop()?b.fadeIn():b.fadeOut()});b.click(function(b){b.preventDefault();a("body, html").animate({scrollTop:0},d.speed)})})}})(jQuery);

// Show password plugin
(function ($) {
    $.toggleShowPassword = function (options) {
        var settings = $.extend({
            field: '.togglePassword',
            control: '.toggleShowPassword'
        }, options);

        var control = $(settings.control);
        var field = $(settings.field)

        control.off('click.passwordToggle').bind('click.passwordToggle', function (event) {
            if (control.find('use').attr('xlink:href') == "#visible-no") {
                field.attr('type', 'text');
                control.find('use').attr('xlink:href', '#visible');
            } else {
                field.attr('type', 'password');
                control.find('use').attr('xlink:href', '#visible-no');
            }
        })
    };
}(jQuery));

// appels ajax de base : en ajoutant la class "ajaxcall" et l'attribut "data-ajaxurl", n'importe quel élément peut faire un appel ajax vers l'URL désignée
// les données supplémentaires à passer sont à renseigner dans des attributes de type data-name
// si un message de confirmation doit etre affiché, il doit etre dans l'attribut data-confirmtext
// si le but est d'ouvrir un modal dynamique, l'attribut "data-target" doit etre égal à "modal"
function ajaxcallInit () {
    var initDoneClass = "ajaxcall-inited";

	$('.ajaxcall[data-ajaxurl]:not(.'+initDoneClass+')').each(function(){
        var that = $(this);
        that
            .click(function(){
                if (that.hasClass('working')) { // traitement déjá en cours
                    return;
                }

                if (!!that.attr('data-confirmtext') && !confirm(that.attr('data-confirmtext'))) { // confirmation nécessaire
                    return;
                }
                
                var isModal = (that.attr('data-target') && that.attr('data-target').indexOf("modal") !== -1);

                that.addClass('working');

                var data = that.data();
                delete data['ajaxurl'];
                delete data['confirmtext'];
                data._token = $('meta[name="csrf-token"]').attr('content');
                
                // suppression éléments liés á qTip
                if ("qtip" in data) {delete data.qtip;}
                if ("hasqtip" in data) {delete data.hasqtip;}
                
                var method = that.attr('data-method') ? that.attr('data-method').toUpperCase() : "POST";
                // spécial Laravel resource controllers
                if ($.inArray(method, ['DELETE', 'PUT', 'PATCH']) !== -1) {
                    data._method = method;
                    method = "POST";
                    delete data['method'];
                }

                $.ajax({
                    type:       method,
                    url:        that.attr('data-ajaxurl'),
                    data:       data,
                    dataType: (isModal) ? "html" : "json",
                    error:      function (data) {
                        that.removeClass('working');
                        alert("Une erreur s'est produite, veuillez retenter ultérieurement");
                    },
                    success:    function (data) {
                        if (isModal) {
                            that.removeClass('working');
                            if (!$('#' + that.attr('data-target')).length) {
                                $('body').append('<div class="reveal fullcontent" id="' + that.attr('data-target') + '" data-reveal data-close-on-click="false" data-multiple-opened="true"></div>');
                                $('#' + that.attr('data-target')).foundation();
                            }
                            $('#' + that.attr('data-target')).html(data).foundation('open');
                            $(document).foundation(); // quickfix pour tabs abonnements et moyens de paiement
                            standardInit();
                        } else {
                            ajaxHandleSuccess(data, that);
                        }
                    }
                });
            })
			.addClass(initDoneClass);
	});
}

function ajaxcontentLoad () {
    $('.ajaxcontent[data-ajaxurl]').each(function(){
        var that = $(this);
        $.ajax({
            type:       "POST",
            url:        that.attr('data-ajaxurl'),
            data:       "_token="+$('meta[name="csrf-token"]').attr('content'),
            success:    function (html) {
                that.html(html).removeClass('ajaxcontent').removeAttr('data-ajaxurl');
            }
        });
    });
}

// status : 0 - loading ; 1 : success, -1 : error, -2 : remove
function ajaxFeedback (status) {
	if (!$('.ajax-feedback').get().length) {
		$('body').prepend("<div class='ajax-feedback'></div>");
	}
	var div = $('.ajax-feedback');
	switch (status) {
		case -2 : div.hide(); return; break;
		case -1 :
			div.html("Une erreur s'est produite, veuillez retenter ultérieurement").removeClass('loading success').addClass('error');
		break;
		case 0 :
			div.html("<img src='/images/gifs/loading-roundarrows.gif' width='15' height='15' /> Opération en cours").removeClass('error success').addClass('loading');
		break;
		case 1 :
			div.html("Opération réussie").removeClass('error loading').addClass('success');
		break;
	}
	div.fadeIn(function(){
		//if (status != 0) {
			setTimeout(function(){
				div.fadeOut();
			}, 3000);
		//}
	});
}

// formulaires de base : submit sur clic sur sendbutton OU entrée dans input ou textarea
function ajaxformInit () {
	var initDoneClass = "ajaxform-sendbutton-inited";

	$('.ajaxform[data-ajaxurl] .sendbutton').each(function(){
		var thisButton = $(this);
		if (!thisButton.hasClass(initDoneClass)) { // bouton pas encore initialisé
			thisButton
				.click(function(){ // sur click l'action d'envoi sera effectuée
					if (!$(this).hasClass('submitSpecial') && !$(this).attr('href')) { // pas d'action spéciale ou lien de redirection défini
						if ($(this).hasClass('needConfirm')) { // si la classe needConfirm est présente, on demande la confirmation
							var confirmText = (!!$(this).attr('data-confirmtext')) ? $(this).attr('data-confirmtext') : "Confirmez l'opération";
							if (confirm(confirmText)) {
								ajaxformSend($(this));
							}
						} else { // submit direct
							ajaxformSend($(this));
						}
					}
				})
				.addClass(initDoneClass);
		}
	});

	// envoie du formulaire si le user tape "entrée" quand le cursor est dans un input
	$('.ajaxform[data-ajaxurl] input').keydown(function (e) {
		if (e.keyCode == 13) {
			ajaxformSend($(this).closest('.ajaxform').find('.sendbutton'));
		}
	});

	// radios / checkboxes plus stylés
	// syntax : <a class='fancyradio {$checked}' data-value='1' data-name='examplefield'>Oui</a> -> va modifier la valeur á "1" du champ "examplefield"
	// si le champ est obligatoire, il faut ajouter les classes "req" et "reqhidden"
	$('.fancyradio[data-name]').click(function(){
		var that = $(this);
		var name = that.attr('data-name');
		var form = that.closest('.ajaxform, .form-wrapper');
		var input = form.find('input[name='+name+']');
		if (that.hasClass('checked')) { // bouton décoché
			that.removeClass('checked');
			input.val('');
		} else {
			form.find('.fancyradio[data-name='+name+']').removeClass('checked');
			that.addClass('checked');
			input.val(that.attr('data-value'));
		}
	});

	// si on veut un bouton "charger plus" peut déclencher le chargement des qu'il devient visible dans l'écran
	$('.ajaxform.ajaxloadmore .sendbutton.loadonappear').appear(function(){
	//$('.ajaxform.ajaxloadmore .sendbutton.loadonappear').on('appear', function(event, $all_appeared_elements) {
		//console.log("appear");
		//$(this).click();
	});

	$('.ajaxform').each(function(){
		$(this).formAddUid();
	});
}

/*
* Vérifie et envoie un formulaire par ajax
* Pour le bon fonctionnement, il faut passer l'objet jQuery du bouton cliqué comme seul paramètre (et ce bouton doit avoir la classe "sendbutton")
* Le bouton doit être dans un wrapper qui a la classe "ajaxform"
* Le wrapper doit avoir l'attribut "data-ajaxurl", car les données seront transmises á cette URL
* Si le bouton contient l'attribut data-callback, cette info sera transmise dans $_POST['callback']
* Si le bouton a l'attribut data-setvalue, la valeur de cet input sera mise á data-value du bouton
*
* Traitement de retour : les données doivent etre passées en format JSON
* ok {bool}* Indique si la requete a réussi
* msg {string} Le message de retour
* redirect {string} URL de redirection
*
* @param {jQuery object} button Le bouton cliqué suite á l'envoi
* @returns NULL
*/
function ajaxformSend (button) {
	var form = button.closest('.ajaxform');
	if (!form.validate()) {
		alert("Veuillez remplir les champs en rouge");
		form.find('.invalid').first().scrollTo();
	} else {
		form.addClass('working');
		var callback = (!!button.attr('data-callback')) ? "&callback="+button.attr('data-callback') : "";
		// le bouton peut mettre une valeur dans le formulaire : le nom de l'input est défini dans l'attribut "data-setvalue" et la valeur á mettre "data-value"
		if (!!button.attr('data-setvalue')) {
			form.find('input[name='+button.attr('data-setvalue')+']').val(button.attr('data-value'));
		}
        
        var revealId = false;
        if (form.find('.modal-working').length) {
            revealId = 'modal-working-' + form.formGetUid();
            if (!$('#'+revealId).length) {
                $('body').append('<div class="reveal modal-working-ajaxform" id="' + revealId + '" data-reveal data-close-on-click="false" data-multiple-opened="true"></div>');
                $('#' + revealId).foundation();
            }
            $('#' + revealId).html(form.find('.modal-working')[0].outerHTML).foundation('open');
        }

		$.ajax({
			type:       "POST",
			url:        form.attr('data-ajaxurl'),
			data:       "_token="+$('meta[name="csrf-token"]').attr('content')+"&"+form.serializeAnything()+callback,
			dataType:   "json",
            error:      function (data) {
                form.removeClass('working');
                if (revealId) {$('#'+revealId).foundation('close');}
                
                if (typeof data.responseText != "undefined") {
                    var errors = $.parseJSON(data.responseText);
                    var html = "";
                    for (var field in errors) {
                        var input = form.find('input[name^='+field+'], select[name^='+field+']');
                        var inputWrapper = input.first().closest('.input-wrapper');
                        if (inputWrapper.length) {
                            inputWrapper.addClass('invalid');
                        } else {
                            input.addClass('invalid');
                        }
                        html += "<p>"+errors[field]+"</p>";
                    }
                    /*if (!$('#modal').length) {
                        $('body').append('<div class="reveal" id="modal" data-reveal></div>');
                        $('#modal').foundation();
                    }
                    $('#modal').html(html).foundation('open');*/
                    alert("Veuillez remplir les champs en rouge");
                    form.find('.invalid').first().scrollTo();
                } else {
                    alert("Une erreur s'est produite, veuillez retenter ultérieurement");
                }
            },
            success:    function (data) {
                form.removeClass('working');
                
                if (revealId) { // modal demandant de patienter affiché
                    if (typeof data.reload == "undefined" && typeof data.redirect == "undefined") { // pas de rechargement ou de redirection de page -> fermeture du modal
                        $('#'+revealId).foundation('close');
                    }
                }
                
                ajaxHandleSuccess(data);
            }
		});
	}
}

/**
 * Traite le retour d'un appel ajax
 * ok {bool}* Détermine si l'appel a réussi
 * msg {string}  Un message á afficher
 * relaod {bool} True pour faire recharger la page actuelle
 * redirect {string} L'adresse URL de redirection
 * callbackFunction {string} Fonction á appeler
 * callbackData {JSON} Données á fournir á la fonction á appeler
 */
function ajaxHandleSuccess (data) {
    if (typeof data.msg != "undefined") { // la réponse a renvoyé un message á afficher
        alert(data.msg);
    }

    if (data.ok) {
        if (typeof data.reload != "undefined") { // la page doit etre rechargée
            window.location.reload();
        }
        if (typeof data.redirect != "undefined") { // le user doit etre redirigé vers une URL donnée
            window.location.href = data.redirect;
        }
        if (typeof data.callbackFunction != "undefined") { // une fonction doit etre appelée
            var callbackData = (typeof data.callbackData != "undefined") ? $.parseJSON(data.callbackData) : null;
            if (typeof data.callbackObject != "undefined") {
                window[data.callbackObject][data.callbackFunction](callbackData);
            } else {
                window[data.callbackFunction](callbackData);
            }
        }
    }
}

function autogrowInit () {
	/*$('textarea.autogrow').each(function(){
		var attr = $(this).attr('max-height');
		if (typeof attr !== 'undefined' && attr !== false) {
			var maxH = parseInt($(this).attr('max-height'));
		} else {
			var maxH = false;
		}
		//var maxH = ($(this).hasAttr('min-height')) ? parseInt($(this).attr('min-height')) : false;
		if (maxH) {
			$(this).autoResize({maxHeight:maxH});
		} else {
			$(this).autoResize();
		}
		$(this).removeClass('autogrow');
	});*/
    $('textarea.autogrow[maxheight]').each(function(){
        $(this)
            .css({
                maxHeight:parseInt($(this).attr('maxheight')),
                resize:'none'
            })
            .removeAttr('maxheight');
    });
    autosize($('textarea.autogrow'));
}

// le wrapper doit avoir la classe "checkboxes-wrapper", le bouton la classe "toggler", les checkbox á basculer la classe "totoggle"
function checkboxToggler (button) {
	var wrapper = button.closest('.checkboxes-wrapper');

	if (wrapper.find('.totoggle:checked').get().length == wrapper.find('.totoggle').get().length) { // tout est coché, on décoche tout
		wrapper.find('input[type=checkbox]').prop('checked',false);
	} else { // il y a des non-cochés, on coche tout
		wrapper.find('input[type=checkbox]').prop('checked',true);
	}
}

function ckeditorInit () {
	var toolbar = [
        { name: 'basicstyles', items : [ 'Bold','Italic','Link','Image','Table','NumberedList','BulletedList','Outdent','Indent','RemoveFormat' ] },
        { name: 'clipboard', items : [ 'Cut','Copy','Paste','PasteText','PasteFromWord','Undo','Redo','Source' ] }
    ];
	$('textarea.ckeditorSimple').each(function(){
		$(this).ckeditor(
            function() {},
            {
                //autoGrow_minHeight:	600,
                //autoGrow_maxHeight:	500,
				disableNativeSpellChecker: false,
                extraPlugins:   	'autogrow',
                filebrowserUploadUrl: '/ajax/ajax-ckeditor.php?f=editorUploadImage',
                pasteFromWordRemoveStyles: true,
                removePlugins:  	'elementspath,liststyle,tabletools,contextmenu,autosave',
                title:				false,
                toolbar:        	toolbar
            }
        );
	});
}

function decodeEmails () {
	$('.encoded-email').each(function(){
		var email = $(this).text().replace("[kukac]","@");
		$(this).replaceWith("<a class='txtcol' href='mailto:"+email+"'>"+email+"</a>");
	});
}

function ibanFormInit () {
	// Passage automatique d'un case à l'autre dans le form iban
	var form = $('.form-iban');
	form.find('.iban-input').keyup(function(){
		if ($(this).val().length == $(this).attr('maxlength')) {
			if ($(this).attr('name') == "iban-6") {
				form.find('input[name=bic]').focus();
			} else {
				$(this).next().focus();
			}
		}
	});

	// Calcul de la clé quand on a mis les 2 chiffres
	// Algo de : http://marlot.org/util/calcul-de-la-cle-rib.php
	$('.iban-input-6').keyup(function() {
		if ($(this).val().length == 3) {
			var banque = parseRIBField(
				$('.iban-input-1').val().toUpperCase() +
				$('.iban-input-2').val().substring(0,1).toUpperCase()
			);
			var guichet = parseRIBField(
				$('.iban-input-2').val().substring(1,4).toUpperCase() +
				$('.iban-input-3').val().substring(0,2).toUpperCase()
			);
			var compte = parseRIBField(
				$('.iban-input-3').val().substring(2,4).toUpperCase() +
				$('.iban-input-4').val().toUpperCase() +
				$('.iban-input-5').val().toUpperCase() +
				$('.iban-input-6').val().substring(0,1).toUpperCase()
			);
			var cle = $('.iban-input-6').val().substring(1,3).toUpperCase();
			var goodcle = 97 - ( ( 89 * banque + 15 * guichet + 3 * compte ) % 97 );
			//console.log(goodcle);
			$('.iban-feedback').hide();
			if (goodcle == cle) {
				$('input[name=iban-valid]').val(1);
			} else {
				$('.iban-feedback.error').show();
				$('input[name=iban-valid]').val(0);
			}
		}
	});
}

function initLiveSearches () {
	$('.livesearch')
		.attr('autocomplete',"off")
		.keyup(function(e) {
			clearTimeout(LSTimer);
			var fname = $(this).attr('data-callback');
			var input = $(this);
			LSTimer = setTimeout(function() {
				switch (fname) {
					/*case 'softwareSearch' :
						documentFilters.search();
					break;*/
					default :
						window[fname]($(this));
				}
			}, 500);
		});
}

/**
 * Si l'on veut que suite á un clic la page scrolle á l'endroit souhaité (au lieu de tomber brusquement, comme par défaut)
 * il faut mettre l'attribut "data-fragment" sur le lien (sans le '#') sur le lien concerné
 * L'attribut href peut etre rempli pour que le fallback soit correctement géré, mais il est enlevé lors du 1er clic sur le lien
 * La balise cible doit avec l'attribut "name" qui correspond á l'attribut "data-fragment" du client
*/
function linkfragmentsAsScroll () {
	//$('a[href*=#]').click(function(){
		//if ($('[name="' + $.attr(this, 'href').substr(1) + '"]').get().length) {
	$('a[data-fragment]').click(function() {
		if ($('[name='+$(this).attr('data-fragment')+']').get().length) {
			$(this).removeAttr('href');
			$('html, body').animate({
				//scrollTop: $('[name="' + $.attr(this, 'href').substr(1) + '"]').offset().top, // - $('#ra-header-wrapper').outerHeight(),
				scrollTop: $('[name='+$(this).attr('data-fragment')+']').offset().top,
				easing: 'easeOutQuad'
			}, 500, function(){

			});
			return false;
		} else {
			return true;
		}
    });
}

function qtipInit () {
	/*$('[qtip-tooltip!=""]').qtip({ // Grab all elements with a non-blank data-tooltip attr. //
		content: {
			attr: 'qtip-tooltip' // Tell qTip2 to look inside this attr for it's content
		},
		position: {
			my: 'top left',  // Position my top left...
			at: 'top right', // at the bottom right of...
			//target: $('.doqtip') // my target
		},
		style: {
			classes: 'qtip-light'
		}
	});*/
	$('[qtip-tooltip!=""]').each(function(){
		switch ($(this).attr('qtip-showon')) {
			case "focus" :
				var show = {event: "focus"};
				//var hide = "blur";
				var hide = {event: "unfocus"};
			break;
			case 'false' :
				var show = {event: false};
				var hide = {event: "unfocus"};
				// pour ouvrir le qtip associé : $('selector').qtip('api').toggle(true);
			break;
			case 'hovering' :
				var show = {event: "mouseenter"};
				var hide = {
					fixed: true,
					delay: 300
				}
			break;
			default :
				var show = {event: "mouseenter"};
				var hide = {event: "mouseleave"};
		}
		/*switch ($(this).attr('qtip-hideon')) {
			case 'distance' : hide = {distance: 200}; break;
		}*/
		var content = ($(this).attr('qtip-ajax')) ? {
			text: function(event, api) {
				$.ajax({
					url: api.elements.target.attr('qtip-ajax') // Use href attribute as URL
				})
				.then(function(content) {
					// Set the tooltip content upon successful retrieval
					api.set('content.text', content);
				}, function(xhr, status, error) {
					// Upon failure... set the tooltip content to error
					api.set('content.text', status + ': ' + error);
				});

				return 'Chargement en cours...'; // Set some initial text
			}
		} : {
			attr: 'qtip-tooltip'
		};
		var style = ($(this).attr('qtip-style')) ? $(this).attr('qtip-style') : "qtip-dark"; // "qtip-light", "qtip-red"
		var positionMy = ($(this).attr('qtip-position-my')) ? $(this).attr('qtip-position-my') : "top center"; // Position my top left...
		var positionAt = ($(this).attr('qtip-position-at')) ? $(this).attr('qtip-position-at') : "bottom center"; // at the bottom right of...
		$(this).qtip({ // Grab all elements with a non-blank data-tooltip attr. //
			/*content: {
				attr: 'qtip-tooltip' // Tell qTip2 to look inside this attr for it's content
			},*/
			content: content,
			position: {
				my: positionMy,
				at: positionAt,
				position: { viewport: $(window) }
				//target: $('.doqtip') // my target
			},
			style: {
				classes: style
			},
			show: show,
			hide: hide
		});
	});
}

// quand on clique dans un textarea on sélectionne le contenu entier
function selectAllOnClickInit () {
    $('.selectAllOnClick:not(.selectAllOnClick-inited)').each(function(){
        $(this).focus(function() {
            var textarea = $(this);
            textarea.select();

            // Work around Chrome's little problem
            textarea.mouseup(function() {
                // Prevent further mouseup intervention
                textarea.unbind("mouseup");
                return false;
            });
        });
        $(this).addClass('selectAllOnClick-inited');
    });
}

function standardInit () {
	// textareas avec le plugin autogrow
	autogrowInit();

	// textareas avec le plugin ckeditor
	//ckeditorInit();

    // élément avec tooltip
	qtipInit();

    //$('.chosenSimple').chosen({allow_single_deselect: true, search_contains:true }); // , width: "100%"
	$('.chosenSimple').each(function(){
		var that = $(this);
		that.chosen({
			allow_single_deselect: true,
			search_contains: true,
			width: (that.hasClass('inline') ? null : "100%")
		});
	})
    //$('.datepickerSimple').datepicker();
	//$('.fancyboxSimple').fancybox();

    ajaxcontentLoad();

	ajaxformInit();

    ajaxcallInit();

	// champs de livesearch
	//initLiveSearches();

	//zip2cityInit();

    //selectAllOnClickInit();
}

// initialise les formulaires contenant un champ code postal et un champ ville
// quand le cp est renseigné, on vérifie s'il existe et on met á jour les possibilités de la ville
// utilisation :
// - mettre la classe "zip2city-wrapper" sur le wrapper
// - mettre la classe "zip-input" sur l'input pour le cp (conseillé á ajouter la classe "input-only-numeric" aussi)
// - mettre la classe "city-input" sur l'input de la ville
function zip2cityInit () {
	$(".zip2city-wrapper:not('.zip2city-inited')").each(function(){
		var form = $(this);
		form.formAddUid();
		form.find('.zip-input').keyup(function(){
			var zip = $(this).val();
			if (zip.length == 5) {
				$.ajax({
					type:       "POST",
					url:        "/ajax/ajax-public.php?f=zip2city",
					data:       "zip="+zip+"&uidForm="+form.find('input[name=uidForm]').val(),
					dataType:   'jsonp'
				});
			}
		});
		form.addClass('zip2city-inited');
	});
}
